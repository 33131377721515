import store from '@/store';
import { isSystemField } from './formFields';

export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export function escapeHtml(text) {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    // криво конфертируется обратно внутри style [&<>"']
    // '"': '&quot;',
    // "'": '&#039;',
  };

  const charsToReplace = Object.keys(map).join('');
  const regexp = new RegExp(`[${charsToReplace}]`, 'g');

  return text.replace(regexp, (m) => map[m]);
}

const HTML_BLOCK_REGEXP = /(<code [\s\S]*?data-language="html"[\s\S]*?>)([\s\S]*?)(<\/code>)/g;

export function escapeWysiwygHtmlBlocks(text) {
  return text
    .replace(/\n{2,}/g, '\n')
    .replace(
      HTML_BLOCK_REGEXP,
      (...matches) => `${matches[1]}${escapeHtml(matches[2])}${matches[3]}`,
    );
}

export function unescapeWysiwygHtmlBlocks(text) {
  return text
    .replace(/\n{2,}/g, '\n')
    .replace(
      HTML_BLOCK_REGEXP,
      (...matches) => `${matches[1]}${htmlDecode(matches[2])}${matches[3]}`,
    );
}

export function deepFind(source, path) {
  try {
    return path.split('.').reduce((result, prop) => result[prop], source);
  } catch (e) {
    throw new Error(
      `Can't get property with path \`${path}\` in object: ${JSON.stringify(source)}`,
    );
  }
}

export function deepClone(source) {
  if (Array.isArray(source)) {
    return source.map((arrayItem) => deepClone(arrayItem));
  }

  if (source && typeof source === 'object') {
    return Object.entries(source).reduce((clone, [key, value]) => {
      clone[key] = deepClone(value);
      return clone;
    }, {});
  }

  return source;
}

export function prepareKanbanCardLayout(fieldsMeta, fieldsListConfig) {
  return fieldsListConfig
    .filter(({ hidden }) => !hidden)
    .map((fieldConfig) => ({
      ...fieldsMeta.find((meta) => meta.name === fieldConfig.name),
      ...fieldConfig,
    }))
    .filter(({ renderer }) => !['color'].includes(renderer));
}

export function sortDataEntriesByField(list, field, inverse = false) {
  return list.sort((a, b) => {
    let w = 0;
    a = (a.data || a)[field];
    b = (b.data || b)[field];

    if (a === null) w = 1;
    else if (b === null) w = -1;
    else {
      const number = Number(a);
      if (!Number.isNaN(number) && typeof Number(a) === 'number') w = a - b;
      else if (typeof a === 'string') {
        if (a > b) w = 1;
        else if (a < b) w = -1;
      }
    }

    return w * (inverse ? -1 : 1);
  });
}

export const preparePayload = function preparePayload(rawData, fieldsMeta) {
  const data = {};

  Object.values(fieldsMeta).forEach((meta) => {
    if (isSystemField(meta)) return;

    // eslint-disable-next-line no-use-before-define
    data[meta.name] = preparePayloadItem(rawData[meta.name], meta);
  });

  return data;
};

function preparePayloadItem(value, meta, isArrayElement = false) {
  if (meta.renderer === 'boolean') {
    value = !!value;
  } else if (meta.renderer === 'image') {
    value = value || null;
  } else if (Array.isArray(value) && meta.renderer !== 'array') {
    value = value.map((item) => preparePayloadItem(item, meta, true));
  } else if (meta.renderer === 'json') {
    if (typeof value === 'string') value = JSON.parse(value);
    value = value || null;
  } else if (meta.renderer === 'enum') {
    value = value || null;
  } else if (meta.renderer === 'gpoint') {
    const coords = (value || '').replace(/[() ]/g, '').split(',');
    if (coords.length < 2) value = undefined;
    else value = `(${coords[0]},${coords[1]})`;
  } else if (meta.renderer === 'ref') {
    value = value?.value || value || null;
  } else if (
    !isArrayElement &&
    (meta.renderer === 'refs' ||
      (['ref2', 'ref-like'].includes(meta.renderer) && meta.config?.multiple === true))
  ) {
    value = value || [];
  } else if (['string', 'text', 'wysiwyg'].includes(meta.renderer)) {
    value = (value ? value?.toString() : value) || null;
    if (value && meta.renderer === 'wysiwyg') {
      value = unescapeWysiwygHtmlBlocks(value);
    }
  } else if (meta.renderer === 'number') {
    value = Number(value) || null;
  } else if (['component', 'embed'].includes(meta.renderer)) {
    if (isArrayElement) {
      meta = meta.typesDict[value.type];

      if (!meta) {
        throw new Error(store.state.transProvider.$t('embed.noMetaError', { type: value.type }));
      }

      if (!value.data) {
        throw new Error(store.state.transProvider.$t('embed.noDataError', { type: value.type }));
      }

      value = {
        type: value.type,
        data: preparePayload(value.data, meta.fields),
      };
    } else {
      value = value || [];
    }
  }

  return value;
}
