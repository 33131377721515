<template>
  <div :class="['auth', { 'auth--single-provider': availableProviders.length < 2 }]">
    <a-form-model
      ref="form"
      class="auth__form"
      layout="vertical"
      :model="form"
      :rules="formRules"
      @submit.prevent="authorize"
    >
      <a-spin
        class="edit-form-spinner"
        :spinning="loading"
      >
        <div class="auth__title">
          <h1>{{ $t('authorization.formTitle') }}</h1>
          <locale-switcher v-if="showLocaleSwitcher" />
        </div>

        <a-tabs v-model="activeProviderIndex">
          <a-tab-pane
            v-for="(provider, index) of availableProviders"
            :key="index"
            :tab="provider.title"
          />
        </a-tabs>

        <a-form-model-item prop="username">
          <a-input
            v-model="form.username"
            autocomplete="off"
            :placeholder="$t('authorization.login')"
          >
            <a-icon
              slot="prefix"
              type="user"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input
            v-model="form.password"
            autocomplete="off"
            :placeholder="$t('authorization.password')"
            type="password"
          >
            <a-icon
              slot="prefix"
              type="lock"
            />
          </a-input>
        </a-form-model-item>
        <div style="position: relative; width: 100%; height: 10px">
          <div
            v-if="requestError"
            style="position: absolute; bottom: 8px; color: #f5222d"
          >
            {{ requestError }}
          </div>
        </div>
        <a-form-model-item prop="rememberMe">
          <a-row
            type="flex"
            justify="space-between"
            align="middle"
            :gutter="20"
          >
            <a-col>
              <a-checkbox v-model="form.rememberMe">
                {{ $t('authorization.rememberMe') }}
              </a-checkbox>
            </a-col>
            <a-col class="auth__buttons">
              <a-button
                type="primary"
                htmlType="submit"
              >
                {{ $t('authorization.logIn') }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-row
          type="flex"
          justify="end"
          class="auth__links"
        >
          <a-button
            type="link"
            :disabled="!gapiAuth2"
            @click="gauthAuthorize"
          >
            {{ $t('authorization.logInWithGoogle') }}
          </a-button>
        </a-row>
      </a-spin>
    </a-form-model>
  </div>
</template>

<script>
import store from '@/store';
import { storage, parseJson } from '@/helpers';
import { appSettings } from '@/AppSettings';
import CONFIG_QUERY from '@/queries/config';
import LocaleService from '@/services/LocaleService';
import LocaleSwitcher from '@/components/header/LocaleSwitcher.vue';

const GAPI_CLIENT_ID = '557988416429-bpraa47r1uc2tagtp6u1iei58purfro3.apps.googleusercontent.com';

export default {
  name: 'AuthPage',

  components: {
    LocaleSwitcher,
  },

  apollo: {
    authConfig: {
      ...CONFIG_QUERY,
      variables() {
        return {
          name: 'glob_Auth',
        };
      },
      update({ table }) {
        if (table?.totalCount) {
          return parseJson(table.documents[0].data.config);
        }

        return {
          providers: [
            {
              title: '',
              endpoint: storage.get('VUE_APP_AUTH_URL') || process.env.VUE_APP_AUTH_URL,
            },
          ],
        };
      },
      error(error) {
        this.emitError(this.$t('authorization.error.configRequest'), error.message);
      },
    },
  },

  data() {
    return {
      gapiAuth2: null,
      loading: false,
      requestError: null,
      activeProviderIndex: 0,
      form: {
        username: '',
        password: '',
        rememberMe: true,
      },
      formRules: {
        username: [{ trigger: 'change', validator: this.validateUsername }],
        password: [{ trigger: 'change', validator: this.validatePassword }],
      },
    };
  },

  computed: {
    availableProviders() {
      return this.authConfig?.providers.filter((provider) => !provider.disabled) || [];
    },
    activeProvider() {
      return this.availableProviders[this.activeProviderIndex];
    },
    showLocaleSwitcher: () => LocaleService.hasMultipleLanguages,
  },

  created() {
    window.gapi?.load('auth2', this.initGauth);
  },

  methods: {
    initGauth() {
      this.gapiAuth2 = window.gapi.auth2.init({
        client_id: GAPI_CLIENT_ID,
      });
    },

    gauthAuthorize() {
      this.loading = true;
      this.gapiAuth2.grantOfflineAccess().then(this.gauthSignInCallback);
    },

    gauthSignInCallback(authResult) {
      if (authResult.code) {
        let authApproveUrl =
          storage.get('VUE_APP_GOOGLE_AUTH_URL') || process.env.VUE_APP_GOOGLE_AUTH_URL;
        if (!authApproveUrl.startsWith('http'))
          authApproveUrl = `${window.location.origin}${authApproveUrl}`;
        authApproveUrl += `?code=${authResult.code}`;
        this.sendAuthRequest(authApproveUrl, null, this.$t('authorization.error.googleAuth'));
      } else if (authResult.error) {
        this.loading = false;
        if (authResult.error !== 'popup_closed_by_user') {
          this.requestError = authResult.details || authResult.error;
        }
      }
    },

    validateUsername(rule, value, callback) {
      if (!value) {
        this.requestError = null;
        callback(new Error(this.$t('authorization.error.loginEmpty')));
      } else {
        callback();
      }
    },

    validatePassword(rule, value, callback) {
      if (!value) {
        this.requestError = null;
        callback(new Error(this.$t('authorization.error.passwordEmpty')));
      } else {
        callback();
      }
    },

    clearForm() {
      Object.assign(this.form, {
        username: '',
        password: '',
        rememberMe: true,
      });
    },

    authorize() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitRequest();
        }
      });
    },

    submitRequest() {
      this.sendAuthRequest(
        this.activeProvider.endpoint,
        JSON.stringify(this.form),
        this.$t('authorization.error.wrongCredentials'),
      );
    },

    sendAuthRequest(uri, payload, errorText) {
      this.loading = true;
      this.requestError = null;
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          this.loading = false;
          if (xhr.status === 200) {
            this.onAuth();
          } else if (xhr.status >= 500) {
            this.emitError(this.$t('authorization.error.serverError'), xhr.message || xhr.error);
          } else {
            this.requestError = errorText;
          }
        }
      };

      xhr.open('POST', uri, true);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.send(payload);
    },

    async onAuth() {
      this.clearForm();

      const path = this.$route.query.backUrl || '/';

      await appSettings.load(store, this);
      await store.state.user.loadInfo();

      if (!store.state.user.roles.includes('ROLE_ADMIN')) {
        store.mutate.logout();
        this.requestError = this.$t('authorization.error.nonAdmin');
        return;
      }

      this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss">
.auth {
  @include fixedBox();
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__form {
    min-width: 400px;
    padding: 20px 20px 40px;
  }

  &__buttons {
    text-align: right;
  }

  &__links {
    margin-top: -20px;
    button {
      margin-right: -16px;
    }
  }

  h1 {
    margin-bottom: 0;
    font-size: 22px;
  }

  &--single-provider {
    h1 {
      margin-bottom: 25px;
    }
    .ant-tabs {
      display: none;
    }
  }
}
</style>
