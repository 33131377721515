import { ApolloClient } from 'apollo-client';
import VueApollo from 'vue-apollo';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'unfetch/polyfill'; // for IE
import { storage, Cookies } from '@/helpers';
import store from '@/store';
import router from '@/router';

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export function createApolloClient({ apiUri }) {
  const httpLink = new HttpLink({
    uri: apiUri,
    credentials: 'include',
  });

  const updateToken = () =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(xhr.message || xhr.error);
          }
        }
      };

      xhr.open('POST', process.env.VUE_APP_AUTHREFRESH_URL, true);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.send(
        JSON.stringify({
          refreshToken: Cookies.get('J_REFRESH'),
        }),
      );
    });

  const forceRelogin = () => {
    let path = router.getRoutes().find((route) => route.name === 'AuthPage').path;
    if (window.location.pathname !== '/') {
      path += `?backUrl=${window.location.pathname}`;
    }

    store.mutate.logout();
    window.location.href = path;
  };

  const apolloClient = new ApolloClient({
    fetchOptions: { fetch },
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      onError(({ networkError, operation, forward, response }) => {
        let statusCode = networkError?.statusCode;

        const errors = (Array.isArray(response?.errors) && response.errors) || [];
        // errors[0]?.extensions?.errorCode старый вариант ответа
        // errors[0]?.code новый вариант ответа / 02.11.2022
        if (errors[0]?.code === 401 || errors[0]?.extensions?.errorCode === 401) {
          statusCode = 401;
        } else if (errors[0]?.code === 403 || errors[0]?.extensions?.errorCode === 403) {
          statusCode = 403;
        }

        if (statusCode === 401 && store.mutate.isAuthorized()) {
          if (!Cookies.get('J_REFRESH')) {
            return forceRelogin();
          }

          return new Observable((observer) => {
            updateToken()
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                observer.error(error);
                forceRelogin();
              });
          });
        }

        if (statusCode === 403) {
          forceRelogin();
        } else {
          forward(operation);
        }
      }),
      requestLink,
      httpLink,
    ]),
  });

  return apolloClient;
}

const api2client = createApolloClient({
  apiUri: storage.get('VUE_APP_API2_URL') || process.env.VUE_APP_API2_URL,
});

const api1client = createApolloClient({
  apiUri: storage.get('VUE_APP_API_URL') || process.env.VUE_APP_API_URL,
});

export const apolloProvider = new VueApollo({
  defaultClient: api1client,
  clients: {
    api2client,
    api1client,
  },
});
