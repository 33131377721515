import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthPage from '@/components/auth/AuthPage';
import ThePage from '@/components/page/ThePage';
import EntityPage from '@/components/edit-form/EntityPage';
import DraftComparePage from '@/components/drafts/DraftComparePage';
import { Cookies } from '@/helpers';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Root',
    meta: {
      redirectIfNotExist: true,
    },
  },
  {
    path: '/auth',
    name: 'AuthPage',
    component: AuthPage,
  },
  {
    path: '/page/:type/model',
    name: 'DataPageModel',
    component: ThePage,
    meta: {
      redirectIfNotExist: true,
    },
  },
  {
    path: '/page/:type/popup/:pType/:pId',
    name: 'DataPagePopup',
    component: ThePage,
    meta: {
      redirectIfNotExist: true,
    },
  },
  {
    path: '/page/:type/:id?/:action?',
    name: 'DataPage',
    component: ThePage,
    meta: {
      redirectIfNotExist: true,
    },
    props: {
      rowData: true,
    },
  },
  {
    path: '/process/:type',
    name: 'ProcessPage',
    component: EntityPage,
    props: true,
    meta: {
      redirectIfNotExist: true,
    },
  },
  {
    path: '/edit/:type/:id?',
    name: 'EntityPage',
    component: EntityPage,
    props: true,
  },
  {
    path: '/drafts',
    name: 'Drafts',
    component: () => import('@/components/drafts/DraftsPage.vue'),
  },
  {
    path: '/drafts/:type/:id?',
    name: 'DraftCompare',
    component: DraftComparePage,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthorized = Cookies.get('J_SESSION');
  if (to.name !== 'AuthPage' && !isAuthorized) {
    const query = {};
    if (window.location.pathname !== '/') {
      query.backUrl = window.location.pathname;
    }

    Cookies.delete('J_REFRESH');

    router.push({
      name: 'AuthPage',
      query,
    });
  } else if (to.name === 'AuthPage' && isAuthorized) {
    router.push({ name: 'Root' });
  } else {
    next();
  }
});

export default router;
